.listItem {
  display: flex;
  color: white;
  background-color: #191c20;
  padding: 0.6rem;
  margin: 0.35rem;
  font-size: 0.9rem;
  border-radius: 0.2rem;
  align-items: center;

  h4 {
    margin: 0;
    color: mediumslateblue;
  }

  h6 {
    margin: 0;
    margin-top: 0.3rem;
    color: #ccc;
    font-weight: 400;
    font-size: 0.7rem;
  }

  div {
    flex: auto;
  }
}
