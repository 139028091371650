.BookCreate {
  display: flex;
  justify-content: center;
  background: white;
  margin: 2rem 20rem 2rem 20rem;
  padding: 1rem;
  border-radius: 0.2rem;

  form {
    width: 100%;

    label {
      margin-top: 0.4rem;
    }

    .FormControl {
      display: flex;
      flex-direction: column;

      input {
        padding: 0.7rem;
        margin-top: 0.5rem;
        color: #424242;
        border: 0.1rem solid #ddd;
        border-radius: 0.2rem;
      }
    }

    button {
      width: 100%;
      padding: 0.75rem 1rem;
      background-color: mediumslateblue;
      border: none;
      color: white;
      border-radius: 0.2rem;
      font-size: 0.8rem;
      cursor: pointer;
      text-decoration: none;
      margin-top: 1rem;

      &:hover {
        background-color: slateblue;
      }
    }
  }
}
